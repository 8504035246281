import {updateVH} from "@/tarot_box/main.js";

export function logEvent(eventName, properties = {}) {
    // 현재 URL 경로 및 쿼리 파라미터 가져오기
    const currentPath = window.location.pathname;
    const urlParams = new URLSearchParams(window.location.search);

    // boxId 추출 (URL 경로에서)
    const boxIdMatch = currentPath.match(/\/tarot_box\/(input_v2|result_v2|checkout)\/(\d+)/);
    if (boxIdMatch) {
        properties['mastro_box_id'] = Number(boxIdMatch[2]);
    }

    // /home 또는 /home2 경로 확인
    if (currentPath === '/tarot_box/home') {
        properties['mastro_box_id'] = 1;
    } else if (currentPath === '/tarot_box/home2') {
        properties['mastro_box_id'] = 2;
    }

    // URL 파라미터에서 box_id 추출 및 mastro_box_id로 설정
    const boxIdParam = urlParams.get('box_id');
    if (boxIdParam) {
        properties['mastro_box_id'] = Number(boxIdParam);
    }

    // 기존 이벤트 로깅 로직
    window.gtag('event', 'w_' + eventName, properties);
    console.log("%clogWebEvent:", "background-color: red; color: white; font-weight: bold;", 'w_'+eventName, properties);

    if (eventName === 'purchase_completed_into') {
        window.gtag('event', 'purchase', properties);
        console.log("%clogWebEvent:", "background-color: red; color: white; font-weight: bold;", 'GA4-purchase', properties);
    }
}

export async function showDailyFortune() {
    let dataToSend = { action: 'showDailyFortune' };
    window.sendInfoToApp(JSON.stringify(dataToSend));
    console.log("showDailyFortune", JSON.stringify(dataToSend));
}

export async function hideBottomBar() {
    let dataToSend = { action: 'hideBottomTabBar' };
    window.sendInfoToApp(JSON.stringify(dataToSend));

    let snakeCaseData = { action: 'hide_bottom_bar' };
    window.callNativeMethod(JSON.stringify(snakeCaseData));

    // console.log("hideBottomBar", JSON.stringify(dataToSend));
    for (let i = 0; i < 6; i++) {
        setTimeout(updateVH, i * 50);
    }
}

export async function showBottomBar() {
    changeBottomSafeAreaColor('#FF171B2B');
    let dataToSend = { action: 'showBottomTabBar' };
    window.sendInfoToApp(JSON.stringify(dataToSend));

    let snakeCaseData = { action: 'show_bottom_bar' };
    window.callNativeMethod(JSON.stringify(snakeCaseData));

    // console.log("showBottomBar", JSON.stringify(dataToSend));
    for (let i = 0; i < 6; i++) {
        setTimeout(updateVH, i * 50);
    }
}

export function changeTopSafeAreaColor (color) {
    let dataToSend = { action: 'changeTopSafeAreaColor', color: color };
    window.sendInfoToApp(JSON.stringify(dataToSend));
    // console.log("changeTopSafeAreaColor", JSON.stringify(dataToSend));
}

export function requestInAppReview() {
    let dataToSend = { action: 'requestInAppReview'};
    window.sendInfoToApp(JSON.stringify(dataToSend));
    // console.log("requestedInAppReview", JSON.stringify(dataToSend));
}

export function changeBottomSafeAreaColor (color) {
    let dataToSend = { action: 'changeBottomSafeAreaColor', color: color };
    window.sendInfoToApp(JSON.stringify(dataToSend));
    // console.log("changeBottomSafeAreaColor", JSON.stringify(dataToSend));
}


export function loadNaverCommonScript() {

    const checkConversionScriptExecuted = () => {
        return window._nasa && window._nasa["cnv"];
    };
    const loadScript = () => {
        const script = document.createElement("script");
        script.src = "https://wcs.naver.net/wcslog.js";
        script.onload = () => {
            if (!window.wcs_add) window.wcs_add = {};
            window.wcs_add["wa"] = "s_4ba4c64e2399";
            if (!window._nasa) window._nasa = {};
            if (window.wcs) {
                window.wcs.inflow();
                if (typeof window.wcs_do === "function") {
                    window.wcs_do(window._nasa);
                }
            }
        };
        // console.log('naverlog common script loaded');
        document.head.appendChild(script);
    };
    if (!checkConversionScriptExecuted()) {
        setTimeout(() => {
            loadScript();
        }, 3000);
    } else {
        loadScript();
    }
}

export function loadNaverConversionScript(conversionType, conversionValue) {
    if (!window._nasa) window._nasa = {};
    if (window.wcs) {
        window._nasa["cnv"] = window.wcs.cnv(conversionType, conversionValue); // 예시 값, 실제 값에 따라 변경 필요
        // console.log('conversion script loaded', conversionType, conversionValue);
    } else {
        const script = document.createElement("script");
        script.src = "https://wcs.naver.net/wcslog.js";
        script.onload = () => {
            window._nasa["cnv"] = window.wcs.cnv(conversionType, conversionValue); // 전환유형, 전환가치 설정
            // console.log('conversion script loaded', conversionType, conversionValue);
        };
        document.head.appendChild(script);
    }
}
