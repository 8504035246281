<template>
  <div class="time-input" v-safe-area-bottom>
    <!-- 토글 스위치 -->
    <div class="time-toggle">
      <div class="time-toggle-texts">
        <span class="title4">I don't know</span>
        <span class="text2"
              :style="{ color: iDunno ? 'var(--Color-palette-System-color-Red)' : 'var(--Grayscale-Gray7)' }">
          If birth time is unknown, AM 00:00 is used.
        </span>
      </div>
      <div class="alarm-toggle-button">
        <label class="switch">
          <input type="checkbox" v-model="iDunno" @change="handleDunnoChange">
          <span class="slider round"></span>
        </label>
      </div>
    </div>
    <div class="time-select" :class="{ 'disabled': iDunno }" style="font-family: 'SUIT-Variable', sans-serif;">
      <div class="time-focus" >
        <div class="title1" :class="{ 'gray-text': iDunno }" style="flex:2; transform:translateX(65%)"></div>
        <div class="title1" :class="{ 'gray-text': iDunno }" style="flex:2; transform:translateX(100%)">:</div>
        <div class="title1" :class="{ 'gray-text': iDunno }" style="flex:2; transform:translateX(70%)"></div>
      </div>
      <!-- AM/PM Picker -->
      <swiper :slides-per-view="3" :centered-slides="true" :space-between="0"
              direction="vertical" class="ampm-swiper" @slideChange="onAmPmChange"
              :initial-slide="initialAmPmIndex" :allow-touch-move="!iDunno" ref="ampmSwiper">
        <swiper-slide v-for="ampm in ampmOptions" :key="ampm">
          <div class="swiper-item" :class="{ 'gray-text': iDunno }">{{ ampm }}</div>
        </swiper-slide>
      </swiper>

      <!-- Hour Picker -->
      <swiper :slides-per-view="5" :centered-slides="true" :space-between="0"
              direction="vertical" class="hour-swiper" @slideChange="onHourChange"
              :initial-slide="initialHourIndex" :allow-touch-move="!iDunno" ref="hourSwiper">
        <swiper-slide v-for="hour in hours" :key="hour">
          <div class="swiper-item" :class="{ 'gray-text': iDunno }">{{ hour }}</div>
        </swiper-slide>
      </swiper>

      <!-- Minute Picker -->
      <swiper :slides-per-view="5" :centered-slides="true" :space-between="0"
              direction="vertical" class="minute-swiper" @slideChange="onMinuteChange"
              :initial-slide="initialMinuteIndex" :allow-touch-move="!iDunno" ref="minuteSwiper">
        <swiper-slide v-for="minute in minutes" :key="minute">
          <div class="swiper-item" :class="{ 'gray-text': iDunno }">{{ minute }}</div>
        </swiper-slide>
      </swiper>
    </div>
    <div class="confirm-button buttonL" @click="confirmSelectedTime">
      DONE
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper-bundle.css';
import {logEvent} from "@/tarot_box/helper/native_api";

export default {
  name: 'TimeInput',
  props: {
    title: String,
    subTitle: String,
    modelValue: String,
    typeOwned: {
      type: String,
      default: null
    }
  },
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      selectedTime: '',
      time: {
        ampm: 'AM',
        hours: '00',
        minutes: '00',
      },
      ampmOptions: ['AM', 'PM'],
      iDunno: false,
      initialAmPmIndex: 0,
      initialHourIndex: 0,
      initialMinuteIndex: 0,
    };
  },
  computed: {
    hours() {
      return this.time.ampm === 'AM'
          ? Array.from({ length: 12 }, (_, i) => (i === 0 ? '00' : (i).toString().padStart(2, '0')))
          : Array.from({ length: 12 }, (_, i) => (i + 1).toString().padStart(2, '0'));
    },
    minutes() {
      return Array.from({ length: 60 }, (_, i) => i.toString().padStart(2, '0'));
    }
  },
  async beforeMount() {
  },
  methods: {
    async parseTime(value) {
      if (value === "I don't know") {
        this.iDunno = true;
        this.updateSelectedTime();
        return;
      }

      const [ampm, time] = value.split(' ');
      let [hours, minutes] = time.split(':');

      // AM/PM에 따른 12시 처리
      if (ampm === 'AM' && hours === '12') {
        hours = '00'; // 자정은 00시로 처리
      } else if (ampm === 'PM' && hours !== '12') {
        hours = (parseInt(hours) % 12 + 12).toString().padStart(2, '0');
      }

      this.time = {
        ampm,
        hours: hours.padStart(2, '0'),
        minutes: minutes.padStart(2, '0')
      };

      // Swiper의 초기 인덱스를 설정
      this.initialAmPmIndex = this.ampmOptions.indexOf(ampm);
      this.initialHourIndex = this.hours.indexOf(hours.padStart(2, '0'));
      this.initialMinuteIndex = this.minutes.indexOf(minutes.padStart(2, '0'));

      this.updateSelectedTime();
    },
    onAmPmChange(swiper) {
      if (!this.iDunno) {
        this.time.ampm = this.ampmOptions[swiper.activeIndex];
        this.updateSelectedTime();
      }
    },
    onHourChange(swiper) {
      if (!this.iDunno) {
        this.time.hours = this.hours[swiper.activeIndex];
        this.updateSelectedTime();
      }
    },
    onMinuteChange(swiper) {
      if (!this.iDunno) {
        this.time.minutes = this.minutes[swiper.activeIndex];
        this.updateSelectedTime();
      }
    },
    updateSelectedTime() {
      this.selectedTime = this.iDunno
          ? 'AM 00:00'
          : `${this.time.ampm} ${this.time.hours}:${this.time.minutes}`;
    },
    confirmSelectedTime() {
      const value = this.iDunno || !this.selectedTime ? 'AM 00:00' : this.selectedTime;
      this.$emit('select', {
        value: value,
        inputFormType: 'timeInput',
        iDunno: this.iDunno
      });
    },
    handleDunnoChange() {
      if (this.iDunno) {
        this.time = { ampm: 'AM', hours: '00', minutes: '00' };
        logEvent(`boxinput_${this.typeOwned}_time_unknown_select`,{})
      }
      this.updateSelectedTime();

      this.$nextTick(() => {
        if (this.$refs.ampmSwiper && this.$refs.ampmSwiper.swiper) {
          this.$refs.ampmSwiper.swiper.slideTo(0);
        }
        if (this.$refs.hourSwiper && this.$refs.hourSwiper.swiper) {
          this.$refs.hourSwiper.swiper.slideTo(0);
        }
        if (this.$refs.minuteSwiper && this.$refs.minuteSwiper.swiper) {
          this.$refs.minuteSwiper.swiper.slideTo(0);
        }
      });
    }
  },
  emits: ['select']
};
</script>

<style scoped>
.time-input {
  margin-top: 80px;
  position: relative;
  padding: 0 20px;
  box-sizing: border-box;
  overflow-x: hidden;
}

.time-toggle{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  margin-left: -20px;
  margin-right: -20px;
  padding: 13px 24px;
  box-sizing: border-box;
  background: var(--Grayscale-Gray11);
  border: 1px 0 1px 0 solid var(--Grayscale-Gray10);
}

.time-toggle-texts{
  display: flex;
  flex-direction: column;
}

.time-select {
  position: relative;
  display: flex;
  width: 100%;
  padding: 0 30px;
  margin-bottom: 20px;
  box-sizing: border-box;
  height: 200px;
  justify-content: space-between;
  align-items: center;
  font-family: "SUIT Variable", sans-serif;
}

.time-focus {
  position: absolute;
  width: 100%;
  height: 44px;
  background: var(--Grayscale-Gray11);
  border-radius: 8px;
  z-index: -1;
  top: 50%;
  transform: translateY(-50%);
  left: 0;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 30px;
  box-sizing: border-box;
}

.ampm-swiper {
  flex:2 ;
  width: 60px;
  height: 120px;
  text-align: left;
}

.hour-swiper {
  flex: 2;
  width: 60px;
  height: 180px;
  text-align: center;
}

.minute-swiper {
  flex: 2;
  width: 60px;
  height: 180px;
  text-align: center;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  font-weight: bold;
  color: var(--Grayscale-Gray8);
}

.swiper-slide-active {
  font-weight: bold;
  color: var(--Grayscale-Gray2);
  font-size: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-item{
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "SUIT Variable", sans-serif;
}

.time-select.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.gray-text {
  color: var(--Grayscale-Gray8) !important;
}

/* 토글 버튼 스타일 */
.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 24px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: var(--Color-palette-Primary-Default);
}

input:checked + .slider:before {
  transform: translateX(21px);
}

.confirm-button{
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  margin: 16px 0;
  padding: 16px 0;
  box-sizing: border-box;
  font-family: "SUIT Variable", sans-serif;
  background: var(--Grayscale-Gray3);
  border-radius: 8px;
  color: var(--Grayscale-Gray12);
}
</style>