<template>
  <div class="confirm-popup-back-page">
    <div class="confirm-popup">
      <div class="confirm-popup-contents">
        <div class="confirm-popup-contents-title">
          나의타로 목록 보관기간 안내
        </div>
      </div>

      <ShimmerImage
          :height-ratio=299/280
          :image-style="{width: '80vw'}"
          :src="require('@/tarot_box/pages/my_tarot/assets/images/history_policy.svg')"
      />
      <div class="confirm-popup-button-area">
        <div class="confirm-popup-button" v-wave @click="closeDonePopup">
          Close
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ShimmerImage from "@/tarot_box/components/shimmer/ShimmerImage.vue";

export default {
  name: 'HistoryPolicyPopup',
  components: {
    ShimmerImage,
  },
  props: {
    closePopupCallback: {
      type: Function,
    },
  },
  emits: ['close-popup'],
  methods: {
    closeDonePopup() {
      this.$emit('close-popup');
    },
  },
}
</script>

<style scoped>
.confirm-popup-back-page{
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  background: rgba(0, 0, 0, 0.80);
}

.confirm-popup{
  position: absolute;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 25px;
  width: 90%;
  height: fit-content;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 15px;
  box-sizing: border-box;

  border-radius: 12px;
  background: #FFF;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.15);
}

.confirm-popup-contents{
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  gap: 12px;
}

.confirm-popup-contents-title{
  color: #000;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
}

.confirm-popup-contents-delete {
  text-align: center;
  color: #000;
  font-size: 14px;
  font-weight: 400;
}

.confirm-popup-button-area {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  gap: 9px;
}

.confirm-popup-button {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 0;

  background: #1E2352;
  color: #FFF;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 700;
}

.icon-area {
  max-width: 90%;
}

span {
  color: #3140B4;
}
</style>