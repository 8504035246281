<template>
  <div class="sheet-container">
    <div class="sheet-title">
      <strong>Share</strong>
      <img :src="require('@/tarot_box/pages/box_result_v2/assets/xbutton.svg')" alt="close" @click="$emit('close')"/>
    </div>
    <div class="share-menu">
      <div class="share-item" @click="linkShare">
        <img :src="require('../../assets/bottom-sheet/link.svg')" alt="kakaotalk"/>
        <div>Share Link</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ShareComponent',
  emits: ['link-share', 'kakao-share'],
  data() {
    return {
      buildNumber: parseInt(this.$route.query.build_number),
      isAndroidWebView: false,
    }
  },
  mounted() {
    this.checkWebView();
  },
  methods: {
    linkShare(){
      this.$emit('link-share');
    },
    checkWebView() {
      const userAgent = navigator.userAgent.toLowerCase();
      this.isAndroidWebView = userAgent.includes('android');
    },
  }
}
</script>

<style scoped>
.sheet-container{
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  width: 100%;
  padding: 12px 24px 76px 24px;
  box-sizing: border-box;
  position: relative;
}

.sheet-title{
  font-family: "SUIT Variable", sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  padding: 30px 20px 10px 20px;
  box-sizing: border-box;
  background: white;
  z-index: 9999;
}

.share-menu{
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  width: 100%;
  margin-top: 60px;
}

.share-item{
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  width: 100%;
  gap: 10px;
  padding: 20px 0;
  border-bottom: 1px solid var(--Grayscale-Gray10, #ECECEC);
}

.share-item:last-child{
  border-bottom: none;
}
</style>