<template>
  <div class="modal" :class="{ 'is-active': isActive }" v-if="isActive">
    <div class="overlay" @click="backgroundClick"></div>
    <div class="popup-container2">
      <div class="selections2">
        <span class="title2">
          Cancel payment?
        </span>
      </div>
      <div class="popup_footer2">
        <button class="button1" @click="goHome">Cancel</button>
        <button class="button2" @click="backgroundClick">Continue</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CheckoutVerifyPopup",
  data() {
    return {
    }
  },
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['closeExitPopup'],
  methods: {
    backgroundClick() {
      this.$emit('closeExitPopup');
    },
    goHome() {
      this.$router.push('/tarot_box/home?is_web=true');
    }
  }
}
</script>

<style scoped>
.overlay {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 500;
}

.popup-container2 {
  position: fixed;
  top: 50%; /* 화면의 수직 중앙에 위치하도록 설정 */
  left: 50%; /* 화면의 수평 중앙에 위치하도록 설정 */
  transform: translate(-50%, -50%); /* 팝업의 중심을 화면 중심에 정확히 맞추기 위해 */
  width: 80vw;
  max-width: 432px;
  height: 120px;
  padding: 18px 0 18px 0;
  background-color: #FFFFFF;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.selections2{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.popup_footer2{
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 20px;
}

.popup_footer2 button{
  height: 48px;
  width: 52%;
  padding: 0 32px;
  box-sizing: border-box;
  background: var(--Grayscale-Gray8);
  display: flex;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  color: #FFFFFF;
}

.button2{
  background: var(--Grayscale-Gray3) !important;
}
</style>