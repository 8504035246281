<template>
  <div @keyup.stop="handleKeyup">
    <VueBottomSheet
        :max-height="sheetHeight"
        :can-swipe="false"
        :overlay-click-close="false"
        :transition-duration="0.3"
        @opened="$emit('opened')"
        @closed="onClose"
        ref="bottomSheet">
      <div class="bottom-sheet-title" style="font-family: 'SUIT-Variable', sans-serif;">
        <span class="title3" v-if="currentSubTitle">Please select {{ currentSubTitle }}</span>
        <span class="gray5 title5" @click="close">CLOSE</span>
      </div>
      <MultiChoiceGrid
          v-if="type === 'multiChoiceGrid'"
          v-model="selectedValue"
          :options="currentOptions"
          :title="currentTitle"
          :sub-title="currentSubTitle"
          @select="onSelect"
      />
      <TimeInput
          v-else-if="type === 'timeInput'"
          v-model="selectedValue"
          :title="currentTitle"
          :sub-title="currentSubTitle"
          :type-owned="typeOwned"
          @select="onSelect"
      />
      <LocationInput
          v-else-if="type === 'locationInput'"
          v-model="selectedValue"
          :type-owned="typeOwned"
          @select="onSelect"/>
    </VueBottomSheet>
  </div>
</template>

<script>
import VueBottomSheet from "@webzlodimir/vue-bottom-sheet";
import "@webzlodimir/vue-bottom-sheet/dist/style.css";
import multiChoiceGridDataset from "@/tarot_box/pages/box_input_v2/data/multiChoiceGridDataset.json";
import MultiChoiceGrid from '@/tarot_box/pages/box_input_v2/components/Inputs/MultiChoiceGrid.vue';
import TimeInput from '@/tarot_box/pages/box_input_v2/components/Inputs/TimeInput.vue';
import LocationInput from '@/tarot_box/pages/box_input_v2/components/Inputs/LocationInput.vue';

import { put } from 'cox-postposition';


export default {
  name: "BottomSheet",
  components: {
    VueBottomSheet,
    MultiChoiceGrid,
    TimeInput,
    LocationInput
  },
  data() {
    return {
      sheetHeight: 400,
      payload: null,
      currentOptions: [],
      currentTitle: '',
      currentSubTitle: '',
      type: '',
      subType: '',
      typeOwned: '',
      selectedValue: null,
    };
  },
  emits: ['select', 'closed', 'opened', 'keyup'],
  mounted() {
    this.calculateSheetHeight();
    window.addEventListener('resize', this.calculateSheetHeight);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.calculateSheetHeight);
  },
  methods: {
    open(payload) {
      this.payload = payload;
      this.type = payload.inputFormType;
      this.subType = payload.inputFormSubtype;
      this.selectedValue = payload.value;
      if (payload.key.includes('my')) {
        this.typeOwned = 'myinfo';
      } else if(payload.key.includes('partner')){
        this.typeOwned = 'otherinfo';
      }

      if (payload.inputFormType === 'multiChoiceGrid') {
        const data = multiChoiceGridDataset.multiChoiceGridDatasets[payload.inputFormSubtype];
        if (data) {
          this.currentOptions = data.options;
          this.currentTitle = data.title;
          this.currentSubTitle = data.subTitle;
        } else {
          this.currentOptions = payload.options.choices;
          this.currentTitle = payload.inputFormHeaderLabel;
          this.currentSubTitle = payload.inputFormQuestion;
        }
      } else if (payload.inputFormType === 'timeInput') {
        this.currentTitle = payload.inputFormHeaderLabel;
        this.currentSubTitle = payload.inputFormQuestion;
      } else if (payload.inputFormType === 'locationInput') {
        this.currentTitle = payload.inputFormHeaderLabel;
        this.currentSubTitle = payload.inputFormQuestion;
      }
      this.$nextTick(() => {
        this.calculateSheetHeight();
        this.$nextTick(() => {
          this.$refs.bottomSheet.open();
        });
      });
    },
    calculateSheetHeight() {
      if(this.type === 'timeInput'){
        this.sheetHeight = Math.round(window.innerHeight * 0.7);
      } else if(this. type === 'locationInput'){
        this.sheetHeight = Math.round(window.innerHeight * 0.9);
      } else if(this.type === 'multiChoiceGrid') {
        if(this.subType === 'bloodType') {
          this.sheetHeight = 400;
        } else {
          this.sheetHeight = Math.round(window.innerHeight * 0.7);
        }
      } else {
        this.sheetHeight = 250;
      }
    },
    onClose() {
      this.$emit('closed');
    },
    onSelect(value) {
      this.$emit('select', {
        key: this.payload.key,
        value: value.value || value,
        inputFormType: this.payload.inputFormType,
        inputFormSubtype: this.payload.inputFormSubtype
      });
      this.close();
    },
    getParticle(title) {
      return put(title, '을');
    },
    close() {
      try {
        this.$refs.bottomSheet.close();
      } catch (error) {
        console.warn('Failed to close bottom sheet:', error);
      }
    },
    handleKeyup(event) {
      this.$emit('keyup', event);
    }
  }
}
</script>

<style scoped>
@font-face{
  font-family: "SUIT-Variable";
  src: url("https://cdn.jsdelivr.net/gh/sun-typeface/SUIT@2/fonts/static/woff2/SUIT.css") format("stylesheet");
}

.title3, .title5{
  font-family: "SUIT Variable", sans-serif;
}

.bottom-sheet-title {
  font-family: "SUIT Variable", sans-serif;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 28px 24px 16px 24px;
  box-sizing: border-box;
  z-index: 9999;
}


.bottom-sheet__content{
  height: auto !important;
}

:root {
  --bottom-sheet-padding: 0px;
}

</style>
