<template>
  <div class="city-search-input" v-safe-area-bottom>
    <!-- I don't know 토글 추가 -->
    <div class="idk-toggle">
      <div class="idk-toggle-texts">
        <span class="title4">I don't know</span>
        <span class="text2"
              :style="{ color: iDunno ? 'var(--Color-palette-System-color-Red)' : 'var(--Grayscale-Gray7)' }">
          if birth city is unknown, Eastern Time Zone is used.
        </span>
      </div>
      <div class="alarm-toggle-button">
        <label class="switch">
          <input type="checkbox" v-model="iDunno" @change="handleDunnoChange">
          <span class="slider round"></span>
        </label>
      </div>
    </div>
    <div class="search-container" :class="{ 'disabled': iDunno }">
      <input
          v-model="searchQuery"
          @input="onInputChange"
          placeholder="Choose a location"
          class="search-input"
          ref="searchInput"
          :disabled="iDunno"
      />
    </div>
    <div class="search-results" :class="{ 'disabled': iDunno }">
      <div
          v-for="city in searchResults"
          :key="city.id"
          class="city-item title4"
          @click="selectCity(city)"
          :class="{ 'selected': city.text === selectedCity }"
      >
        <span v-html="highlightText(city.text)"></span>
        <svg v-if="city.text === selectedCity" class="check-icon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="20" height="20" fill="white"/>
          <path d="M6 9L9.90476 14L14.6667 6" stroke="#333333" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
    </div>
    <div class="confirm-button buttonL" @click="confirmSelectedCity" :class="{ 'disabled': !selectedCity && !iDunno }">
      DONE
    </div>
  </div>
</template>

<script>
import { searchCities } from "@/api/geocoding";
import { debounce } from 'lodash';
import {logEvent} from "@/tarot_box/helper/native_api";

export default {
  name: 'CitySearchInput',
  props: {
    typeOwned: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      searchQuery: '',
      searchResults: [],
      selectedCity: null,
      iDunno: false,
    }
  },
  created() {
    this.debouncedSearchCity = debounce(async () => {
      if (this.iDunno) return;
      try {
        const response = await searchCities(this.searchQuery, 'en');
        this.searchResults = response.data.slice(0, 5); // 최대 5개의 결과만 표시
        if (!this.searchResults.some(city => city.text === this.selectedCity)) {
          this.selectedCity = null;
        }
      } catch (error) {
        console.error('Error searching cities:', error);
        this.searchResults = [];
      }
    }, 300);
  },
  methods: {
    onInputChange() {
      this.debouncedSearchCity();
    },
    selectCity(city) {
      this.selectedCity = city.text;
      this.searchQuery = city.text;
    },
    confirmSelectedCity() {
      if (this.iDunno || this.selectedCity) {
        this.$emit('select', {
          value: this.iDunno ? 'Birth City Unknown' : this.selectedCity,
          inputFormType: 'cityInput',
          iDunno: this.iDunno
        });
      }
      this.searchResults = [];
      this.searchQuery = '';
      this.iDunno = false;
    },
    highlightText(text) {
      if (!this.searchQuery) return text;
      const regex = new RegExp(this.searchQuery, 'gi');
      return text.replace(regex, match => `<span style="color: var(--Color-palette-System-color-Blue); font-weight: bold;">${match}</span>`);
    },
    handleDunnoChange() {
      if (this.iDunno) {
        this.searchQuery = '';
        this.selectedCity = null;
        this.searchResults = [];
        logEvent(`boxinput_${this.typeOwned}_city_unknown_select`,{})
      }
    }
  },
  emits: ['select']
}
</script>

<style scoped>
:deep(template){
  font-family: "SUIT-Variable", sans-serif;
}

input, .buttonL{
  font-family: "SUIT-Variable", sans-serif;
}

.city-search-input {
  margin-top: 20px;
  position: relative;
  padding: 0 20px;
  box-sizing: border-box;
}

.search-container {
  margin-top: 20px;
  margin-bottom: 10px;
}

.search-input {
  width: 100%;
  font-size: 18px;
  padding: 14px 16px;
  height: 48px;
  border: 1px solid var(--Grayscale-Gray8);
  border-radius: 8px;
  box-sizing: border-box;
  font-family: "SUIT Variable", sans-serif;
  font-weight: 500;
}

.search-input input::placeholder {
  color: var(--Grayscale-Gray8);
  font-family: "SUIT Variable", sans-serif;
  font-weight: 500;
}

.search-results {
  min-height: 210px;
  overflow-y: auto;
}

.city-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  border-bottom: 1px solid var(--Grayscale-Gray10);
  font-family: "SUIT Variable", sans-serif;
}

.city-item:last-child {
  border-bottom: none;
}

.confirm-button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 16px 0;
  padding: 16px 0;
  box-sizing: border-box;
  background: var(--Grayscale-Gray3);
  border-radius: 8px;
  color: var(--Grayscale-Gray12);
  cursor: pointer;
  font-family: "SUIT Variable", sans-serif;
}

.confirm-button:hover {
  background: var(--Grayscale-Gray4);
}

.confirm-button.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.idk-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 70px;
  margin-bottom: 20px;
  margin-left: -20px;
  margin-right: -20px;
  padding: 13px 24px;
  box-sizing: border-box;
  background: var(--Grayscale-Gray11);
  border: 1px 0 1px 0 solid var(--Grayscale-Gray10);
}

.idk-toggle-texts {
  display: flex;
  flex-direction: column;
}

.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 24px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: var(--Color-palette-Primary-Default);
}

input:checked + .slider:before {
  transform: translateX(21px);
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}
</style>