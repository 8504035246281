<script>
import {changeBottomSafeAreaColor, changeTopSafeAreaColor} from "@/tarot_box/helper/native_api";

export default {
  name: "InputCover",
  props: {
    startPageImgUrl: {
      type: String,
    },
    textColor: {
      type: String,
    },
    tarotBoxData: {
      type: Object,
    },
  },
  emits: ['load', 'go-next'],
  methods: {
    goNext() {
      this.$emit('go-next');
    },
    goBack() {
      this.$router.go(-1);
      changeBottomSafeAreaColor('#FF1E2352');
      changeTopSafeAreaColor('#FFFFFFFF');
    }
  }
}
</script>

<template>
  <div class="input-bottom" v-safe-area-bottom>
    <div v-wave class="cta" @click="goNext()">
      시작하기
    </div>
  </div>
  <img id="cover" :src="startPageImgUrl" @load="$emit('load')" >
  <div class="out-button" @click="goBack">
    <img :src="require('@/tarot_box/pages/box_result_v2/assets/xbutton-lg.svg')" alt="✕">
  </div>
</template>

<style scoped>
img{
  width: 100%;
  height: 100%;
  max-width: 480px;
  object-fit: cover;
  object-position: center;
  overscroll-behavior-y: none;
}

.input-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #fff;
  z-index: 10;
  padding: 10px 24px;
  box-sizing: border-box;
  overscroll-behavior-y: none;

}

.cta{
  display: flex;
  width: 100%;
  height: 44px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: var(--Color-palette-Primary-Default, #1E2352);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  color: var(--Grayscale-Gray12)
}

.out-button{
  position: absolute;
  top: 50px;
  left: 24px;
  width: 40px;
  height: 40px;
  background-color: rgba(0,0,0,0.7);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
  padding: 6px;
  box-sizing: border-box;
}
</style>