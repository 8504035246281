<template>
  <div class="input-screen">
    <Loading v-model:active="isMakeBoxProcessing"
             loader="spinner"
             color="#1E2352"
             :opacity="0"
             :z-index="99999">
    </Loading>
    <TopBar
        v-if="currentIndex > 0"
        :currentIndex="currentIndex"
        :totalIndex="totalIndex"
        @exit="this.isExitPopup=true"/>
    <InputCover
        v-if="currentIndex === 0"
        :startPageImgUrl="startPageImgUrl"
        :textColor="textColor"
        @load="isLoading = false"
        @go-next="goNextPage"/>
    <InputDetails
        v-show="currentIndex > 0 && currentIndex !== totalIndex"
        :user-name="userName"
        :input-sections="inputPageMetaArr"
        :current-index="currentIndex"
        :total-index="totalIndex"
        :tarot-box-data="tarotBoxData"
        @exit="this.isExitPopup=true"
        @proceed="goNextPage"
        @prev="goPreviousPage"
        @prepare="getUserAnswers"
        @warn="showWarnMessage"/>
    <InputTransaction
        v-if="currentIndex === totalIndex"
        :box-id="boxId"
        :tarot-box-data="tarotBoxData"
        @checkout-web="processWebCheckout"
        @checkout-app="processAppCheckout"
        @later="outToMyTarotTab"
        @warn="showWarnMessage"/>
    <BoxPaymentPopup
        v-show="isBoxPaymentPopup === true"
        :box-id="boxId"
        :tarot-box-data="tarotBoxData"
        :user-id="userId"
        :input-data="inputData"
        :sale-price="tarotBoxData.sale_price"
        :original-price="tarotBoxData.original_price"
        :input-tarot-data="inputTarotData"
        :user-phone-number="userPhoneNumber"
        :history-id="historyId"
        @payment-done="goToCheckout"
        @update-user-info="getUserInfo"
        @close="isBoxPaymentPopup = false"/>
    <PaymentDonePopup
        v-if="isPaymentCompletePopup === true"/>

    <Loading v-model:active="isLoading"
             loader="dots"
             color="#1E2352"
             :opacity="1"
             :z-index="9999">
      <template v-slot:default>
        <div class="custom-loader">
          <img :src="require('@/tarot_box/pages/box_result_v2/assets/main-loading/logo.svg')" alt="loader" class="loader-img"/>
          <img :src="require('@/tarot_box/pages/box_result_v2/assets/main-loading/moving.svg')" alt="moving" class="moving-svg"/>
        </div>
      </template>
    </Loading>
    <ExitPopup v-if="isExitPopup" @exit="outToMainPage" @close-popup="isExitPopup = false"/>
    <div class="toast" v-show="showToast" :class="{ 'show': showToast }">{{toastMessage}}</div>
    </div>
</template>

<script>
import {
  changeBottomSafeAreaColor,
  changeTopSafeAreaColor,
  hideBottomBar,
  logEvent
} from "@/tarot_box/helper/native_api";
import {mapActions, mapGetters} from "vuex";
import {
  getMastroBoxList,
  getTarotBoxInputDataForMastro,
  getTarotUserInfo,
  makeBoxResult, purchaseBox
} from "@/tarot_box/helper/mytarot_api";

import InputCover from "@/tarot_box/pages/box_input_v2/components/InputCover.vue";
import InputDetails from "@/tarot_box/pages/box_input_v2/components/InputDetails.vue";
import TopBar from "@/tarot_box/pages/box_input_v2/components/TopBar.vue";
import InputTransaction from "@/tarot_box/pages/box_input_v2/components/InputTransaction.vue";
import BoxPaymentPopup from "@/tarot_box/pages/box_input_v2/components/Popups/BoxPaymentPopup.vue";
import PaymentDonePopup from "@/tarot_box/pages/box_input_v2/components/Popups/PaymentDonePopup.vue";
import ExitPopup from "@/tarot_box/pages/box_input_v2/components/Popups/ExitPopup.vue";


import * as Sentry from "@sentry/vue";
import Loading from "vue-loading-overlay";
import moment from "moment";
import {goToMyTarotTab} from "@/common/app_webview_handler";
import {remoteConfig} from "@/common/firebase";

export default {
  name: "InputPage.vue",
  components: {
    PaymentDonePopup,
    BoxPaymentPopup,
    Loading,
    InputCover,
    InputDetails,
    TopBar,
    InputTransaction,
    ExitPopup
  },
  data() {
    return {
      language : "ko",
      isWeb: false,
      boxId: null,
      inputPageMetaArr: [],
      startPageImgUrl: "",
      textColor: '#000000',
      userName: '고객',
      userId: null,
      userPhoneNumber: null,
      userClover: 0,
      buildNumber: 0,

      inputTarotData: [],
      inputData: "",
      tarotBoxData: [],
      paymentPopupData: {},
      historyId: null,
      needTarotSpread: false,
      utm: '',

      currentIndex: 1,
      totalIndex: 2,

      isLoading: true,
      isBoxPaymentPopup: false,
      isPaymentCompletePopup: false,
      isTarotBoxSelectPopup: false,
      isExitPopup: false,
      isMakeBoxProcessing: false,
      isCloverPaywallActive: false,

      showToast: false,
      toastMessage: ''
    };
  },
  mounted() {
    hideBottomBar();
    changeTopSafeAreaColor('#FFFFFFFF');
    changeBottomSafeAreaColor('#FFFFFFFF');
    if (this.language === "") {
      this.language = "ko";
    }
    let queryParams = { ...this.$route.query };
    if (queryParams.os_type === 'web' || queryParams.os_type === 'null') {
      this.isWeb = true;
    } else {
      this.isWeb = false;
    }
    window.onBackButtonClick = this.onBackButtonClick;
    this.$store.dispatch('setInputData', null);
    this.initiateInputPage();
    let url = new URL(decodeURIComponent(window.location.href));
    this.userId = parseInt(url.searchParams.get('tarot_user_id'));
    this.userPhoneNumber = url.searchParams.get('phone_number');
    this.buildNumber = url.searchParams.get('build_number');
    if (this.buildNumber === null || this.buildNumber === undefined){
      this.buildNumber = 78;
    } else {
      this.getPaywallInfo();
    }
    if (this.userId && this.userPhoneNumber){
      this.getUserInfo(this.userId, this.userPhoneNumber);
    }
    setTimeout(() => {
      this.isLoading = false;
    }, 1000);
    let utmContent = new URLSearchParams(window.location.search).get('utm_content');
    if (utmContent) {
      let webReferrer = decodeURIComponent(utmContent);
      this.utm = webReferrer;
      console.log(this.utm);
      this.$store.dispatch('setUserData', {web_referrer: webReferrer});
      this.$store.dispatch('setUtmReferrerData', webReferrer);
      localStorage.setItem('UtmReferrer', webReferrer);
      console.log('success set utmContent', webReferrer);
    }
  },
  beforeUnmount() {
    if (window.onBackButtonClick === this.onBackButtonClick) {
      window.onBackButtonClick = null;
    }
    this.previousSafeAreaColorTop();
    this.previousSafeAreaColorBottom();
  },
  methods: {
    ...mapActions(['setTarotBoxesData','addSafeAreaColorTop', 'addSafeAreaColorBottom', 'previousSafeAreaColorTop', 'previousSafeAreaColorBottom', 'updatePaywall', 'setInputData']),

    onBackButtonClick() {
      if(this.currentIndex === 1 ){
        this.outToMainPage();
      } else {
        return
      }
    },
    async initiateInputPage() {
      this.boxId = parseInt(this.$route.params.boxId);
      const tarotSpread = this.tarotBoxData?.tarotSpread
      if(tarotSpread && tarotSpread.attrs.length >0){
        this.needTarotSpread = true;
      }

      let res = await getTarotBoxInputDataForMastro(this.boxId);
      if (res !== null && res !== undefined && res !=='{}') {
        console.log(res);
        this.inputPageMetaArr = res.inputSections;
        this.totalIndex = this.inputPageMetaArr.length + 1;
        this.startPageImgUrl = res.startPageImgUrl.backgroundImage;
        this.textColor = res.textColor;
        if(this.tarotBoxData){
          logEvent('boxinput_cover_into', {
            'web_referrer' : this.utmReferrer
          })
        }
      } else {
        this.toastMessage = '잠시 후 다시 시도해주세요';
        this.showToast = true;
        setTimeout(() => {
          this.$router.go(-1);
        }, 3000);
        Sentry.captureMessage("InputPage.vue initiateInputPage() res is null");
      }
      let res2 = await getMastroBoxList();
      if (res2.result === 'success'){
        let tarotBoxData = res2.data.find(box => box.id === this.boxId);
        this.tarotBoxData = tarotBoxData;
      }
    },
    async getUserInfo(tarotUserId, phoneNumber) {
      let res = await getTarotUserInfo(tarotUserId, phoneNumber);
      if (res.result === 'success'){
        this.userName = res.data.display_name;
        this.userClover = res.data.clover_balance;
        this.isMakeBoxProcessing = false;
      }
    },
    getUserAnswers(answers) {
      this.inputData = answers;
      this.setInputData(answers);  // Vuex store에 저장
      console.log('inputData', this.inputData);
    },
    async getPaywallInfo() {
      const configValue = remoteConfig.getValue('paywall_banner_list');
      const parsedValue = JSON.parse(configValue.asString());
      console.log('parsedValue', parsedValue)
      this.updatePaywall(parsedValue);
    },
    goNextPage() {
      if(this.currentIndex === 0){
        logEvent('boxinput_cover_start_click', {
          'tarot_box_id': this.tarotBoxData.id,
          'tarot_box_name': this.tarotBoxData.title,
          'web_referrer' : this.utmReferrer
        })
      }
      if(this.tarotBoxData && this.currentIndex < this.totalIndex && this.currentIndex > 0) {
        let sectionType = this.inputPageMetaArr[this.currentIndex -1].sectionType
      let convertedSectionType;
      if (sectionType === 'personalInfo'){
        convertedSectionType = 'myinfo';
      } else if (sectionType === 'partnerInfo'){
        convertedSectionType = 'otherinfo';
      } else if (sectionType === 'survey'){
        convertedSectionType = 'survey';
      } else {
        convertedSectionType = 'additional';
      }
        logEvent(`boxinput_${convertedSectionType}_next_click`, {
          'tarot_box_id': this.tarotBoxData.id,
          'tarot_box_name': this.tarotBoxData.title,
          'section_type': this.inputPageMetaArr[this.currentIndex - 1].sectionType,
          'web_referrer' : this.utmReferrer
        })
      }

      if (this.currentIndex + 1 === this.totalIndex) {
        if (this.needTarotSpread === true) {
          this.isTarotBoxSelectPopup = true;
          return;
        } else {
          this.isBoxPaymentPopup = true;
          logEvent('boxinput_purchase_popup', {
            'web_referrer' : this.utmReferrer
          });
          return;
        }
      }
      if (this.currentIndex < this.totalIndex) {
        this.currentIndex++;
      }
    },
    processInputData(inputData) {
      return inputData.map(item => {
        let value = item.value;

        if (item.key.includes('birth_date')) {
          value = moment(value, 'MM-DD-YYYY').format('YYYY-MM-DD');
        } else if (item.key.includes('birth_time')) {
          value = moment(value, 'A hh:mm').format('HH:mm');
        } else if (item.key.includes('birth_place')) {
          value = value.split(',')[0].trim();
        }
        return { ...item, value };
      });
    },
    // async makeBoxResultForMastro(){
    //   let res = await makeBoxResult(121904, '01032382218', this.inputData, '', this.boxId);
    //   if (res.result === 'success') {
    //     console.log(res.data);
    //     this.historyId = res.data.id;
    //     console.log('historyId', this.historyId)
    //   }
    // },
    // async purchaseAutoForMastro() {
    //   let res = await purchaseBox(121904, '01032382218', this.historyId);
    //   if (res.result === 'success') {
    //     console.log('purchaseAutoForMastro success');
    //   }
    // },
    async makeBoxResultForApp(){
      let pickedCards = this.inputTarotData.join(',');
      this.isMakeBoxProcessing = true;
      let res = await makeBoxResult(this.userId, this.userPhoneNumber, this.inputData, pickedCards, this.boxId);
      if(res.result === 'success'){
        this.paymentPopupData = res.data.payment_popup;
        this.historyId = res.data.id;
        console.log('historyId', this.historyId)
        this.isMakeBoxProcessing = false;
      } else{
        this.showWarnMessage('잠시 후 다시 시도해주세요');
        this.isMakeBoxProcessing = false;
        Sentry.captureMessage(`makeBoxResult for app failed: ${res.message}, ${this.userId}, ${this.userPhoneNumber}, ${this.inputData}, ${pickedCards}, ${this.boxId}`);
      }
    },
    async processAppCheckout() {
      if(this.historyId === null){
        Sentry.captureMessage('historyId is null when processAppCheckout. So retry makeBoxResultForApp: ${this.userId}, ${this.userPhoneNumber}, ${this.inputData}, ${this.inputTarotData}, ${this.boxId}');
        await this.makeBoxResultForApp();
      }

      if (this.userClover < this.tarotBoxData.salePrice) {
        if(this.buildNumber >= 84){
          this.isCloverPaywallActive = true;
        } else{
          let dataToSend = {action: 'showPayWall'};
          window.sendInfoToApp(JSON.stringify(dataToSend));
        }
      } else {
        let res = await purchaseBox(this.userId, this.userPhoneNumber, this.historyId);
        if (res.result === 'success') {
          this.isPaymentCompletePopup = true;
        } else {
          this.showWarnMessage('잠시 후 다시 시도해주세요');
          Sentry.captureMessage(`processAppCheckout failed: ${res.message}, ${this.userId}, ${this.userPhoneNumber}, ${this.historyId}`);
        }
      }
    },
    async goToCheckout() {
      this.isLoading = true;
      let processedInputData;
      if (typeof this.inputData === 'string') {
        try {
          const parsedData = JSON.parse(this.inputData);
          processedInputData = this.processInputData(parsedData);
        } catch (error) {
          console.error('Error parsing inputData:', error);
          this.showWarnMessage('Please try later');
          return;
        }
      } else {
        processedInputData = this.processInputData(this.inputData);
      }
      this.inputData = JSON.stringify(processedInputData);
      this.isLoading = false;
      console.log('final: ', this.inputData);
      this.$store.dispatch('setInputData', this.inputData)
      this.$router.push(`/tarot_box/checkout/${this.boxId}`);
    },
    processWebCheckout() {
      console.log("2")
      this.isLoading = true;
      let parsedUserInputData;
      let processedInputData;
      if (typeof this.inputData === 'string'){
        try {
          parsedUserInputData = JSON.parse(this.inputData);
          processedInputData = this.processInputData(parsedUserInputData);
        } catch (error) {
          console.error('Error parsing inputData:', error);
          this.showWarnMessage('잠시 후 다시 시도해주세요');
          return;
        }
      } else {
        parsedUserInputData = this.inputData;
        processedInputData = this.processInputData(parsedUserInputData);
      }
      this.$store.dispatch('setTempInputData', processedInputData)
      const url = `/tarot_box/checkout/${this.boxId}?&is_web=true`;
      this.$router.push(url);
    },
    showWarnMessage(message){
      this.toastMessage = message;
      this.showToast = true;
      setTimeout(() => {
        this.showToast = false;
      }, 3000);
    },
    goPreviousPage() {
        this.currentIndex--;
    },
    outToMainPage() {
        this.$router.go(-1);
    },
    outToMyTarotTab() {
      if(this.isWeb === true){
        this.$router.go(-1);
      }else{
        goToMyTarotTab();
      }
    },
    handlePaymentDone() {
      this.isBoxPaymentPopup = false;
      this.isPaymentCompletePopup = true;
    },
    getTarotCardsString(pickedCards) {
      this.inputTarotData = pickedCards.split(',').map(Number);
      this.needTarotSpread = false;
      this.goNextPage();
      this.isTarotBoxSelectPopup = false;
    },
    async closePaywall() {
      this.isMakeBoxProcessing = true;
      await this.getUserInfo(this.userId, this.userPhoneNumber);
      this.isCloverPaywallActive = false;
    }
  },
  computed: {
    ...mapGetters(['user', 'tarotBoxes', 'utmReferrer']),
  }
}
</script>

<style scoped>
.input-screen {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: var(--Grayscale-Gray12);
  height: 100dvh;
  width: 100vw;
  max-width: 480px;
  margin: 0 auto;

  overflow-x: hidden;
  overflow-y: scroll;
  overscroll-behavior-y: none;
}

.toast {
  position: fixed;
  top: 84px;
  left: 50%;
  transform: translate(-50%, -60%);
  width: 65vw;
  padding: 16px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: var(--Grayscale-Gray2, #242424);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.20);
  color: var(--Grayscale-Gray12, #FFF);
  text-align: center;
  opacity: 0;
  z-index: 9999;
  transition: opacity 0.5s, transform 0.5s;
}
.toast.show {
  opacity: 1;
  transform: translate(-50%, -50%);
  transition: opacity 0.5s, transform 0.5s;
}


.custom-loader {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-img {
  position: absolute;
  width: 100px; /* 로고 이미지의 크기 */
  height: 100px;
}

.moving-svg {
  position: absolute;
  width: 30px; /* 움직이는 이미지의 크기 */
  height: 30px;
  animation: orbit 2s linear infinite;
}

@keyframes orbit {
  0% {
    transform: rotate(0deg) translateX(50px) rotate(0deg);
  }
  100% {
    transform: rotate(360deg) translateX(50px) rotate(-360deg);
  }
}
</style>
