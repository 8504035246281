<template>
  <div class="detail-wrapper" v-safe-area-top>
    <div class="content-container">
      <div class="proceed-button-wrapper" v-safe-area-bottom
           :class="{ 'show-button': showProceedButton }"
           v-if="!isCheckCompOpened && !isPageChanging">
        <div v-wave
             class="proceed-button-white"
             @click="goPrevPage">BACK</div>
        <div v-wave
             :class=" isNextButtonEnabled ?  'proceed-button' : 'proceed-button-disabled'"
             @click="openAnswerCheckComponent">NEXT</div>
      </div>
      <BottomSheet
          ref="bottomSheet"
          @select="handleBottomSheetSelect"
          @keyup.stop="handleKeyup"
      />
      <SajuInfoPopup v-if="openSajuInfoPopup" @close-popup="openSajuInfoPopup = false"/>
      <transition :name="skipTransition ? '' : 'slide-left'">
        <InputCheckComponent
            v-show="isCheckCompOpened"
            :user-answers="userAnswers"
            :user-name="userName"
            :input-sections="isCheckCompOpened ? inputSections : cachedInputSections"
            :saju-data="sajuData"
            :partner-saju-data="partnerSajuData"
            :current-index="isCheckCompOpened ? currentIndex : cachedCurrentIndex"
            :total-index="totalIndex"
            :tarot-box-data="tarotBoxData"
            :planetary-position-data="getCurrentPlanetaryPositionData"
            @open-popup="openSajuInfoPopup = true"
            @proceed="goNextPage"
            @close-later="closeCheckComponentClickedLater"
            @close="closeCheckComponent"
        />
      </transition>
      <div class="white-wrapper"
           style="position: fixed; top: 0; left: 0; width: 100%; height: 100%; background-color: white; z-index: 20;"
           v-if="isPageChanging"/>
      <div class="detail-container">
        <div class="sections" :style="{ visibility: isCheckCompOpened ? 'hidden' : 'visible'}">

          <InputHeader
              :key="currentIndex"
              :user-name="userName"
              :input-sections="inputSections"
              :current-index="currentIndex"
          />


          <transition-group name="list" tag="div" class="section-items" mode="out-in">
            <div
                v-for="(inputItem, index) in visibleItems"
                :key="`${inputItem.key}-${inputItem.inputFormType}-${index}`"
                class="item"
                :class="{ 'first-item': index === 0 }"
            >

              <span class="title5 gray5"
                    v-if="inputItem.inputFormType !=='surveyScore'">{{ inputItem.inputFormHeaderLabel }}</span>


              <span class="title5">{{inputItem?.inputFormSubLabel}}</span>


              <TextInput
                  v-if="inputItem.inputFormType === 'textInput'"
                  v-model="inputItem.tempValue"
                  :input-item="inputItem"
                  @submitAnswer="submitAnswer"
                  @invalid-input="isCurrentInputValid = false"
                  @focus="activateItem(inputItem)"
              />
              <NumberInput
                  v-else-if="inputItem.inputFormType === 'numberInput'"
                  v-model="inputItem.tempValue"
                  :input-item="inputItem"
                  @submitAnswer="submitAnswer"
                  @focus="activateItem(inputItem)"
              />
              <DateInput
                  v-else-if="inputItem.inputFormType === 'dateInput'"
                  v-model="inputItem.value"
                  :input-item="inputItem"
                  @warn="handleWarn"
                  @submitAnswer="submitAnswer"
                  @invalidDate="handleInvalidDate"
                  @invalid-input="isCurrentInputValid = false"
                  @focus="activateItem(inputItem)"
              />
              <GenderInput
                  v-else-if="inputItem.inputFormType === 'genderInput'"
                  v-model="inputItem.value"
                  :input-item="inputItem"
                  @submitAnswer="submitAnswer"
                  @focus="activateItem(inputItem)"
              />
              <LongTextField
                  v-else-if="inputItem.inputFormType === 'longTextInput'"
                  v-model="inputItem.tempValue"
                  :input-item="inputItem"
                  @submitAnswer="submitAnswer"
                  @focus="activateItem(inputItem)"
              />
              <SurveyScore
                  v-else-if="inputItem.inputFormType === 'surveyScore'"
                  v-model="inputItem.tempValue"
                  :input-item="inputItem"
                  :key="`survey-${inputItem.key}-${inputItem.inputFormSubtype}`"
                  @submitAnswer="submitAnswer"
                  @focus="activateItem(inputItem)"/>
              <!-- 기타 타입 (BottomSheet 사용) -->
              <div
                  v-else
                  class="select-item"
                  :class="{ 'active': inputItem.isActive, 'completed': inputItem.isCompleted }"
                  @click="activateItem(inputItem)"
              >
                <span class="title3">
                  {{ inputItem.isCompleted ? inputItem.value : inputItem.inputFormHeaderLabel }}
                </span>
                <img :src="require('@/tarot_box/pages/box_input_v2/assets/Input/select.svg')" alt="select"/>
              </div>
            </div>
          </transition-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputHeader from "@/tarot_box/pages/box_input_v2/components/InputHeader.vue";
import TextInput from "@/tarot_box/pages/box_input_v2/components/Inputs/TextInput.vue";
import DateInput from "@/tarot_box/pages/box_input_v2/components/Inputs/DateInput.vue";
import GenderInput from "@/tarot_box/pages/box_input_v2/components/Inputs/GenderInput.vue";
import BottomSheet from "@/tarot_box/pages/box_input_v2/components/BottomSheets/BottomSheet.vue";
import InputCheckComponent from "@/tarot_box/pages/box_input_v2/components/InputCheckComponent.vue";
import {convertSajuData, convertToSajuDateAndTime} from "@/tarot_box/pages/utils/convertSajuData";
import {getPlanetaryPosition, getSajuCharacters} from "@/tarot_box/helper/mytarot_api";
import LongTextField from "@/tarot_box/pages/box_input_v2/components/Inputs/LongTextInput.vue";
import NumberInput from "@/tarot_box/pages/box_input_v2/components/Inputs/NumberInput.vue";
import SurveyScore from "@/tarot_box/pages/box_input_v2/components/Inputs/SurveyScore.vue";
import SajuInfoPopup from "@/tarot_box/pages/box_input_v2/components/Popups/SajuInfoPopup.vue";
import {logEvent} from "@/tarot_box/helper/native_api";
import moment from 'moment';
import _ from 'lodash';
import {mapGetters} from "vuex";


export default {
  name: "InputDetails",
  components: {
    SajuInfoPopup,
    NumberInput,
    LongTextField,
    GenderInput,
    InputHeader,
    TextInput,
    DateInput,
    SurveyScore,
    BottomSheet,
    InputCheckComponent
  },
  props: {
    inputSections: {
      type: Array,
    },
    currentIndex: {
      type: Number,
    },
    totalIndex: {
      type: Number,
    },
    userName: {
      type: String,
    },
    tarotBoxData: {
      type: Object,
    }
  },
  data() {
    return {
      userAnswers: [],
      stringUserAnswers: '',
      sajuData: [],
      partnerSajuData: [],
      isCheckCompOpened: false,
      isPageChanging: false,
      openSajuInfoPopup: false,
      isCurrentInputValid: true,
      cachedInputSections: [],
      cachedCurrentIndex: 0,
      skipTransition: false,
      showProceedButton: false,
      personalPlanetaryPositionData: {},
      partnerPlanetaryPositionData: {},
    }
  },
  mounted() {
    setTimeout(() => {
      this.showProceedButton = true;
    }, 1500);
  },
  computed: {
    ...mapGetters (['utmReferrer']),
    visibleItems() {
      const currentSection = this.inputSections[this.currentIndex - 1];
      if (!currentSection) return [];

      const items = currentSection.inputs;
      const completedItems = items.filter(item => item.isCompleted);
      const incompleteItems = items.filter(item => !item.isCompleted);

      if (incompleteItems.length > 0) {
        return [...completedItems, incompleteItems[0]];
      } else {
        return completedItems;
      }
    },
    userAnswersString() {
      return JSON.stringify(this.userAnswers);
    },
    activeItem() {
      return this.visibleItems.find(item => item.isActive);
    },
    allCompleted() {
      return this.visibleItems.every(item => item.isCompleted);
    },
    isNextButtonEnabled() {
      return this.allCompleted && this.isCurrentInputValid;
    },
    getCurrentPlanetaryPositionData() {
      if (this.currentIndex <= 0 || this.currentIndex > this.inputSections.length) {
        return {};
      }

      const currentSection = this.inputSections[this.currentIndex - 1];
      if (!currentSection) {
        console.warn('No section found for index:', this.currentIndex);
        return {};
      }

      switch(currentSection.sectionType) {
        case 'personalInfo':
          return this.personalPlanetaryPositionData;
        case 'partnerInfo':
          return this.partnerPlanetaryPositionData;
        default:
          console.warn('Unknown section type:', currentSection.sectionType);
          return {};
      }
    }
  },
  watch: {
    currentIndex() {
      this.showProceedButton = false;
      setTimeout(() => {
        this.showProceedButton = true;
      }, 1000);
      if (this.currentIndex < this.totalIndex){
        let sectionType = this.inputSections[this.currentIndex -1].sectionType
        let convertedSectionType;
        if (sectionType === 'personalInfo'){
          convertedSectionType = 'myinfo';
        } else if (sectionType === 'partnerInfo'){
          convertedSectionType = 'otherinfo';
        } else if (sectionType === 'survey'){
          convertedSectionType = 'survey';
        } else {
          convertedSectionType = 'additional';
        }
        logEvent(`boxinput_${convertedSectionType}_into`, {
          'tarot_box_id': this.tarotBoxData.id,
          'tarot_box_name': this.tarotBoxData.title,
          'web_referrer' : this.utmReferrer
        });
      }
    },
    visibleItems: {
      handler(newItems) {
        const allCompleted = newItems.every(item => item.isCompleted);
        if (allCompleted && this.currentIndex !== 0 && this.inputSections && this.inputSections[this.currentIndex - 1]) {
          this.handleAllCompleted();
        }
      },
      deep: true
    }
  },
  emits: ['load', 'go-next', 'prev', 'answer-updated', 'warn', 'proceed', 'prepare', 'exit'],
  methods: {
    activateItem(item) {
      // 현재 활성화된 항목을 비활성화
      this.inputSections[this.currentIndex - 1].inputs.forEach(input => {
        input.isActive = false;
        input.tempValue = input.value || '';
      });
      // 새로운 항목을 활성화
      item.isActive = true;

      // BottomSheet 항목을 자동으로 열기
      if (['multiChoiceGrid', 'timeInput', 'locationInput'].includes(item.inputFormType)) {
        this.$nextTick(() => {
          this.$refs.bottomSheet.open(item);
        });
      }
    },
    handleInvalidDate() {
      if (this.activeItem && !this.activeItem.isCompleted) {
        this.activeItem.isCompleted = false;
      }
    },
    moveToNextItem() {
      const currentSection = this.inputSections[this.currentIndex - 1];
      const nextIncompleteItem = currentSection.inputs.find(item => !item.isCompleted);

      if (nextIncompleteItem) {
        if (nextIncompleteItem.inputFormType === 'timeInput' || nextIncompleteItem.inputFormType === 'multiChoiceGrid' || nextIncompleteItem.inputFormType === 'locationInput') {
          this.openBottomSheet(nextIncompleteItem);
        }
        if (nextIncompleteItem.inputFormType !== 'surveyScore') {
          this.activateItem(nextIncompleteItem);
        }
      } else {
        this.$emit('go-next');
      }
    },
    submitAnswer(item) {
      if (item.value !== undefined) {
        this.isCurrentInputValid = true;
        this.updateAnswer(item, item.value);
        console.log('Item submitted:', item);
        if (!this.allCompleted) {
          if(item.inputFormType === 'multiChoiceGrid' || item.inputFormType === 'timeInput' || item.inputFormType === 'locationInput') {
            setTimeout(() => {
              this.moveToNextItem();
            }, 700);
          } else{
            setTimeout(() => {
              this.moveToNextItem();
            }, 700);
          }
        }
      } else if (item.tempValue && item.tempValue !== '') {
        this.updateAnswer(item, item.tempValue);
        this.isCurrentInputValid = true;
      }
    },
    updateAnswer(item, value) {
      const updatedItem = this.inputSections[this.currentIndex - 1].inputs.find(
          input => input.key === item.key &&
              input.inputFormType === item.inputFormType &&
              input.inputFormSubtype === item.inputFormSubtype
      );

      if (updatedItem) {
        updatedItem.value = value;
        if (!updatedItem.isCompleted) {
          updatedItem.isCompleted = true;
        }
        updatedItem.isActive = false;
      }

      const existingAnswerIndex = this.userAnswers.findIndex(
          answer => answer.key === item.key &&
              answer.inputFormType === item.inputFormType &&
              answer.inputFormSubtype === item.inputFormSubtype
      );

      if (existingAnswerIndex !== -1) {
        this.userAnswers[existingAnswerIndex] = {
          key: item.key,
          value: value,
          inputFormType: item.inputFormType,
          inputFormSubtype: item.inputFormSubtype
        };
      } else {
        this.userAnswers.push({
          key: item.key,
          value: value,
          inputFormType: item.inputFormType,
          inputFormSubtype: item.inputFormSubtype
        });
      }

      this.$emit('answer-updated', this.userAnswersString);
    },
    openBottomSheet(item) {
      this.activateItem(item);
      this.$refs.bottomSheet.open(item);
    },
    handleBottomSheetSelect(selectedData) {
      const item = this.inputSections[this.currentIndex - 1].inputs.find(
          input => input.key === selectedData.key &&
              input.inputFormType === selectedData.inputFormType &&
              input.inputFormSubtype === selectedData.inputFormSubtype
      );
      if (item) {
        this.submitAnswer({
          ...item,
          value: selectedData.value,
          inputFormType: selectedData.inputFormType,
          inputFormSubtype: selectedData.inputFormSubtype
        });
      }
    },
    handleWarn(message) {
      this.$emit('warn', message);
    },
    handleKeyup(event) {
      console.log('Keyup event:', event);
    },
    handleAllCompleted() {
      if (this.currentIndex < this.totalIndex && this.currentIndex > 0) {
        let sectionType = this.inputSections[this.currentIndex - 1].sectionType;
        let convertedSectionType;
        if (sectionType === 'personalInfo') {
          convertedSectionType = 'myinfo';
        } else if (sectionType === 'partnerInfo') {
          convertedSectionType = 'otherinfo';
        } else if (sectionType === 'survey') {
          convertedSectionType = 'survey';
        } else {
          convertedSectionType = 'additional';
        }
        logEvent(`boxinput_${convertedSectionType}_done`, {
          'tarot_box_id': this.tarotBoxData.id,
          'tarot_box_name': this.tarotBoxData.title,
          'section_type': sectionType,
          'web_referrer' : this.utmReferrer
        });
      }
    },
    goPrevPage() {
      if(this.currentIndex === 1){
        this.$emit('exit');
        return;
      }
      this.cachedCurrentIndex = this.currentIndex - 1;
      let cachedSectionType = this.cachedInputSections[this.cachedCurrentIndex - 1].sectionType
      if(cachedSectionType === 'personalInfo' || cachedSectionType === 'partnerInfo'){
        this.isCheckCompOpened = true;
        this.isPageChanging = true;
      }
      this.$emit('prev');
    },
    goNextPage() {
      this.$emit('proceed');
      setTimeout(() => {
        this.isPageChanging = false;
      }, 100);
    },
    closeCheckComponent() {
      this.isCheckCompOpened = false;
    },
    closeCheckComponentClickedLater() {
      let sectionType = this.cachedInputSections[this.currentIndex -1].sectionType
      let convertedSectionType;
      if (sectionType === 'personalInfo'){
        convertedSectionType = 'myinfo';
      } else if (sectionType === 'partnerInfo'){
        convertedSectionType = 'otherinfo';
      } else if (sectionType === 'survey'){
        convertedSectionType = 'survey';
      } else {
        convertedSectionType = 'additional';
      }
      logEvent(`boxinput_${convertedSectionType}_back_click`, {
        'tarot_box_id': this.tarotBoxData.id,
        'tarot_box_name': this.tarotBoxData.title,
        'web_referrer' : this.utmReferrer
      });
      this.skipTransition = true;
      this.isCheckCompOpened = false;
      this.isPageChanging = false;
      // skipTransition을 다시 false로 설정하는 타이머 추가
      setTimeout(() => {
        this.skipTransition = false;
      }, 50);
    },
    async openAnswerCheckComponent() {
      if (!this.allCompleted) {
        this.$emit('warn', '모든 항목을 완료해주세요.');
        return;
      }

      const currentSection = this.inputSections[this.currentIndex - 1];
      if (currentSection && currentSection.sectionType !== 'partnerInfo' && currentSection.sectionType !== 'personalInfo') {
        this.updateUserAnswers();
        this.$emit('proceed');
        return;
      }

      this.cachedInputSections = this.inputSections;
      this.cachedCurrentIndex = this.currentIndex;
      this.isCheckCompOpened = true;
      this.isPageChanging = true;
      await this.processPlanetaryPositionData();
      await this.processSajuData();
      this.updateUserAnswers();
    },


    async processPlanetaryPositionData() {
      const currentSection = this.inputSections[this.currentIndex - 1];

      if (currentSection.sectionType === 'personalInfo') {
        const personalAnswers = this.userAnswers.filter(answer =>
            ['my_name', 'my_birth_date', 'my_birth_time', 'my_birth_place'].includes(answer.key)
        );
        const processedAnswers = this.processAnswers(personalAnswers);
        console.log('Personal Answers:', processedAnswers);
        await this.getPlanetaryPositionDataByFetch(processedAnswers, 'personal');
      } else if (currentSection.sectionType === 'partnerInfo') {
        const partnerAnswers = this.userAnswers.filter(answer =>
            ['partner_name', 'partner_birth_date', 'partner_birth_time', 'partner_birth_place'].includes(answer.key)
        );
        const processedAnswers = this.processAnswers(partnerAnswers);
        console.log('Partner Answers:', processedAnswers);
        await this.getPlanetaryPositionDataByFetch(processedAnswers, 'partner');
      }
    },

    processAnswers(answers) {
      return answers.map(answer => {
        let processedAnswer = { ...answer };

        if(answer.key === 'my_name'){
          this.$store.dispatch('setUserData', {user_nickname: answer.value});
          console.log('userNickname:', answer.value);
        }

        if (answer.inputFormType === 'timeInput') {
          const time = moment(answer.value, 'A hh:mm');
          processedAnswer.value = time.format('HH:mm');
        }
        else if (answer.inputFormType === 'dateInput') {
          const date = moment(answer.value, 'MM-DD-YYYY');
          if (date.isValid()) {
            processedAnswer.value = date.format('YYYY-MM-DD');
          } else {
            console.error(`Invalid date format for ${answer.key}: ${answer.value}`);
            processedAnswer.value = 'Invalid Date';
          }
        }

        return processedAnswer;
      });
    },
    normalizeLocationName(name) {
      return _
          .chain(name)
          .deburr()  // 발음 구별 기호 제거
          .replace(/[^\u0020-\u007E]/g, '')  // 비 ASCII 문자 제거 (공백부터 '~'까지의 문자만 유지)
          .replace(/[^a-zA-Z\s-]/g, '')  // 영문자, 공백, 하이픈 이외의 문자 제거
          .trim()  // 앞뒤 공백 제거
          .replace(/\s+/g, ' ')  // 연속된 공백을 하나로 축소
          .value();
    },
    async getPlanetaryPositionDataByFetch(processedData, type) {
      try {
        const birthDateData = processedData.find(data => data.inputFormSubtype === `birthdate`);
        const birthTimeData = processedData.find(data => data.inputFormSubtype === `birthtime`);
        const birthPlaceData = processedData.find(data => data.inputFormSubtype === `birthlocation`);
        const nameData = processedData.find(data => data.inputFormSubtype === `name`);

        if (!birthDateData || !birthTimeData || !birthPlaceData || !nameData) {
          throw new Error(`필수 데이터가 누락되었습니다. (${type})`);
        }

        const birthDate = birthDateData.value;
        const birthTime = birthTimeData.value;
        const name = nameData.value;
        let birthPlace = birthPlaceData.value.split(',')[0].trim();

        const encodedBirthPlace = encodeURIComponent(this.normalizeLocationName(birthPlace));
        console.log('Encoded Birth Place:', encodedBirthPlace)

        console.time(`getPlanetaryPosition_${type}`);
        const res = await getPlanetaryPosition(birthDate, birthTime, encodedBirthPlace, name);
        console.timeEnd(`getPlanetaryPosition_${type}`);

        if (res.result === 'success') {
          if (type === 'personal') {
            this.personalPlanetaryPositionData = res.data;
            console.log('Personal Planetary Position Data:', this.personalPlanetaryPositionData);
          } else if (type === 'partner') {
            this.partnerPlanetaryPositionData = res.data;
            console.log('Partner Planetary Position Data:', this.partnerPlanetaryPositionData);
          }
        } else {
          throw new Error(`API 호출이 실패했습니다. (${type})`);
        }
      } catch (error) {
        console.error(`${type} Planetary Position Data 가져오기 오류:`, error.message);
      }
    },

    async processSajuData() {
      const currentSection = this.inputSections[this.currentIndex - 1];
      const convertedData = this.convertSajuData();

      if (currentSection.sectionType === 'personalInfo') {
        await this.fetchSajuCharacters(convertedData.saju, 'sajuData');
      } else if (currentSection.sectionType === 'partnerInfo') {
        await this.fetchSajuCharacters(convertedData.sajuPartner, 'partnerSajuData');
      }
    },
    convertSajuData() {
      const sajuAnswers = this.userAnswers.filter(answer => answer.key === 'saju');
      const partnerSajuAnswers = this.userAnswers.filter(answer => answer.key === 'saju_partner');

      let saju = null, sajuPartner = null;

      try {
        if (sajuAnswers.length > 0) {
          saju = {
            data: convertSajuData(sajuAnswers),
            dateAndTime: convertToSajuDateAndTime(sajuAnswers)
          };
        }
        if (partnerSajuAnswers.length > 0) {
          sajuPartner = {
            data: convertSajuData(partnerSajuAnswers),
            dateAndTime: convertToSajuDateAndTime(partnerSajuAnswers)
          };
        }
      } catch (error) {
        console.error('Error converting saju data:', error);
        this.$emit('warn', '사주 데이터 변환 중 오류가 발생했습니다.');
      }

      return { saju, sajuPartner };
    },
    async fetchSajuCharacters(sajuData, storageKey) {
      if (!sajuData || !sajuData.dateAndTime) return;

      try {
        const res = await getSajuCharacters(sajuData.dateAndTime.date, sajuData.dateAndTime.sajutime);
        this[storageKey] = res.data;
      } catch (error) {
        console.error(`Failed to get ${storageKey} characters:`, error);
        this[storageKey] = [];
      }
    },
    updateUserAnswers() {
      // surveyScore가 아닌 답변들을 필터링 합산
      const nonSurveyAnswers = this.userAnswers.filter(answer =>
          !['saju', 'saju_partner'].includes(answer.key) && answer.inputFormType !== 'surveyScore'
      ).map(({ key, value }) => ({ key, value }));

      const surveyScores = this.userAnswers.filter(answer => answer.inputFormType === 'surveyScore');
      const consolidatedScores = {};

      surveyScores.forEach(answer => {
        if (!consolidatedScores[answer.key]) {
          consolidatedScores[answer.key] = 0;
        }
        consolidatedScores[answer.key] += parseInt(answer.value);
      });

      const consolidatedSurveyAnswers = Object.entries(consolidatedScores).map(([key, value]) => ({
        key,
        value: value.toString()
      }));



      // 사주 데이터 변환
      const convertedData = this.convertSajuData();
      const sajuAnswers = [];

      if (convertedData.saju) {
        sajuAnswers.push({
          key: 'saju',
          value: convertedData.saju.data
        });
      }
      if (convertedData.sajuPartner) {
        sajuAnswers.push({
          key: 'saju_partner',
          value: convertedData.sajuPartner.data
        });
      }

      // 모든 답변을 합치기
      const updatedUserAnswers = [
        ...nonSurveyAnswers,
        ...consolidatedSurveyAnswers,
        ...sajuAnswers
      ];

      this.stringUserAnswers = JSON.stringify(updatedUserAnswers);
      console.log('Updated User Answers:', updatedUserAnswers);
      this.$emit('prepare', this.stringUserAnswers);
    },
  }
}
</script>

<style scoped>
.detail-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background-color: var(--Grayscale-Gray12);
  box-sizing: border-box;
  overscroll-behavior: contain;
  overscroll-behavior-y: none;
}

.content-container {
  flex-grow: 1;
  overflow-y: auto;
  padding-bottom: 54px;
  overscroll-behavior: contain;
  overscroll-behavior-y: none;
}

.proceed-button-wrapper {
  position: absolute;  /* absolute 대신 fixed 사용 */
  bottom: -100px;  /* 초기 위치를 화면 밖으로 설정 */
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 24px;
  box-sizing: border-box;
  background-color: white;
  gap: 8px;
  z-index: 30;
  transition: bottom 0.5s ease;  /* 트랜지션 추가 */
}

.proceed-button-wrapper.show-button {
  bottom: 0;  /* 최종 위치 */
}

.proceed-button {
  display: flex;
  flex: 5;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 13px;
  background: var(--Grayscale-Gray3);
  color: white;
  font-size: 16px;
  font-weight: 700;
}

.proceed-button-disabled {
  display: flex;
  flex: 5;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 13px;
  color: white;
  font-size: 16px;
  font-weight: 700;
  pointer-events: none;
  background: var(--Grayscale-Gray10);
}

.proceed-button-white {
  display: flex;
  flex: 2;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 8px;
  padding: 13px;
  background: var(--Grayscale-Gray12);
  color: var(--Grayscale-Gray3);;
  outline: 2px solid var(--Grayscale-Gray3);;
  font-size: 16px;
  font-weight: 700;
}

.detail-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  background-color: white;
  padding: 54px 24px;
  box-sizing: border-box;
  overscroll-behavior: contain;
  overscroll-behavior-y: none;
}

.sections {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  position: relative;
}

.section-items {
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 20px;
  width: 100%;
  gap: 28px;
}

.item {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
}

.title3 {
  color: var(--Grayscale-Gray8);
}

.select-item {
  width: 100%;
  padding: 13px 16px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  border: 2px solid var(--Grayscale-Gray10, #ECECEC);
}

.select-item.active {
  border: 2px solid var(--Grayscale-Gray2, #242424);
}

.select-item.completed .title3 {
  color: var(--Grayscale-Gray3);
}

.input-form {
  margin-top: 10px;
  display: flex;
  width: 100%;
}

.input-form input {
  flex-grow: 1;
  padding: 8px;
  border: 1px solid var(--Grayscale-Gray10);
  border-radius: 4px;
}


.input-form button {
  margin-left: 10px;
  padding: 8px 16px;
  background-color: var(--Grayscale-Gray3);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.confirmation-button {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  box-sizing: border-box;
  background-color: white;
}

.button {
  background: var(--Grayscale-Gray3);
  width: 100%;
  height: 52px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.button.disabled {
  background: var(--Color-palette-Primary-Lighten3, #B9C0D5);
  cursor: not-allowed;
}

.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-active.first-item {
  transition-delay: 0.5s;
}

.list-enter-from {
  opacity: 0;
  transform: translateY(-30px);
}

.list-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.list-move {
  transition: transform 0.5s ease;
}

/* 첫 번째 아이템이 아닌 경우에 대한 딜레이 */
.list-enter-active:not(.first-item) {
  transition-delay: 0.2s;
}

.slide-left-leave-active {
  transition: all 0.5s ease-out;
}

.slide-left-leave-to {
  transform: translateX(-100%);
}

.slide-left-enter-from,
.slide-left-enter-active,
.slide-left-enter-to {
  transition: none;
}


</style>
