<template>
  <div class="long-text-container">
    <div class="question-title">
      <span class="title4" style="color: var(--Color-palette-Primary-Default)">Q.</span>
      <span class="title4">{{inputItem.inputFormQuestion}}</span>
    </div>
    <textarea
        :value="modelValue"
        :placeholder="`Feel free to share what's on your mind.\n
ex)\n
Is there someone who has a crush on me?\n
I feel lonely, but I have no idea where or how to meet the right person.`"
        :maxlength="inputItem.options.maxLength"
        class="text-input"
        @input="onInput"
        @keyup.enter="submitAnswer"
        @blur="submitAnswer"
        @keydown="preventEmoji"
        ref="input"/>
    <div class="text-length">
      <span>{{modelValue.length}}/{{inputItem.options.maxLength}}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "LongTextInput",
  props: {
    inputItem: {
      type: Object,
      required: true
    },
    modelValue: {
      type: String,
      default: ''
    }
  },
  methods: {
    onInput(event) {
      const value = event.target.value.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '');
      this.$emit('focus')
      this.$emit('update:modelValue', value)
      this.autoResize()
    },
    submitAnswer() {
      if (this.modelValue.trim()) {
        this.$emit('submitAnswer', {
          ...this.inputItem,
          value: this.modelValue,
          inputFormType: this.inputItem.inputFormType,
          inputFormSubtype: this.inputItem.inputFormSubtype
        })
        this.$refs.input.blur();
      }
    },
    autoResize() {
      const textarea = this.$refs.input
      textarea.style.height = 'auto'
      textarea.style.height = textarea.scrollHeight + 'px'
    },
    preventEmoji(event) {
      const regex = /^(?:(?![\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF]).)*$/;
      if (!regex.test(event.key)) {
        event.preventDefault();
      }
    }
  },
  mounted() {
    this.$refs.input.focus()
    this.autoResize()
  },
  updated() {
    this.autoResize()
  },
  emits: ['update:modelValue', 'submitAnswer', 'focus']
}
</script>

<style scoped>
.long-text-container{
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  gap: 10px;
  padding-bottom: 22px;
}

.question-title{
  display: flex;
  align-items: flex-start;
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  color: var(--Grayscale-Gray3, #333);
}

.text-input{
  width: 100%;
  min-height: 100px;
  padding: 16px;
  box-sizing: border-box;
  border-radius: 8px;
  resize: none;
  overflow-y: hidden;

  background: var(--Grayscale-Gray12, #F5F5F5);
  outline: 2px solid var(--Grayscale-Gray10);
  color: var(--Grayscale-Gray3, #333);
  font-size: 16px;
  line-height: 120%;
  -webkit-user-select: text;
  user-select: text;
}

.text-input:focus{
  outline: 2px solid var(--Color-palette-Primary-Default);
}

.text-input::placeholder{
  font-size: 16px;
  color: var(--Grayscale-Gray7);
}

.text-length{
  position: absolute;
  bottom: 0;
  right: 0;

  color: var(--Grayscale-Gray6, #999);
  font-size: 12px;
  font-weight: 500;
}
</style>