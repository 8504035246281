<template>
   <div class="bottom-bar" v-safe-area-bottom>
     <div class="shades"/>
     <div class="pagination" @click="toggleBottomSheet">
       <img v-if="tarotBoxHistory.extra_question_status === 'new'" src="@/tarot_box/pages/box_result_v2/assets/bottom-bar/index-icon-new.svg">
       <img v-else :src="require('@/tarot_box/pages/box_result_v2/assets/bottom-bar/index-icon-default.svg')">
       {{currentIndex}} <span class="shade">&nbsp;/ {{totalIndex}}</span>
     </div>
     <div class="navigation">
       <svg @click="goPrev" width="48" height="48" viewBox="-24 -12 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path d="M10.587 11.9969L16.6752 17.3343C16.8821 17.5157 16.988 17.7438 16.9928 18.0185C16.9976 18.2931 16.8917 18.5254 16.6752 18.7152C16.4586 18.9051 16.1961 19 15.8876 19C15.5791 19 15.3165 18.9051 15.1 18.7152L8.38232 12.826C8.24241 12.7033 8.14372 12.574 8.08625 12.4379C8.02875 12.3018 8 12.1548 8 11.9969C8 11.839 8.02875 11.692 8.08625 11.5559C8.14372 11.4198 8.24241 11.2905 8.38232 11.1678L15.1 5.2786C15.3069 5.09715 15.5671 5.00433 15.8804 5.00014C16.1937 4.99592 16.4586 5.08874 16.6752 5.2786C16.8917 5.46843 17 5.69858 17 5.96905C17 6.23952 16.8917 6.46967 16.6752 6.6595L10.587 11.9969Z" :fill="colorizeSVG2"/>
       </svg>
       <svg width="1" height="16" viewBox="0 0 1 16" fill="none" xmlns="http://www.w3.org/2000/svg">
         <line x1="0.5" y1="-2.18557e-08" x2="0.500001" y2="16" stroke="#D9D9D9"/>
       </svg>
       <svg @click="goNext" width="48" height="48" viewBox="0 -12 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path d="M13.413 12.0031L7.32484 6.66569C7.11787 6.48426 7.012 6.25621 7.00721 5.98153C7.0024 5.70686 7.10828 5.47462 7.32484 5.28478C7.54138 5.09493 7.80391 5 8.11242 5C8.42094 5 8.68346 5.09493 8.9 5.28478L15.6177 11.174C15.7576 11.2967 15.8563 11.426 15.9138 11.5621C15.9713 11.6982 16 11.8452 16 12.0031C16 12.161 15.9713 12.308 15.9138 12.4441C15.8563 12.5802 15.7576 12.7095 15.6177 12.8322L8.9 18.7214C8.69305 18.9029 8.43292 18.9957 8.1196 18.9999C7.8063 19.0041 7.54138 18.9113 7.32484 18.7214C7.10828 18.5316 7 18.3014 7 18.031C7 17.7605 7.10828 17.5303 7.32484 17.3405L13.413 12.0031Z" :fill="colorizeSVG"/>
       </svg>
     </div>
   </div>
</template>

<script>
import {logEvent} from "@/tarot_box/helper/native_api";

export default {
  name: "CoverImage",
  props: {
    currentIndex: Number,
    totalIndex: Number,
    isBottomSheetOpen: {
      type: Boolean,
      required: true,
    },
    tarotBoxHistory: {
      type: Object,
    },
  },
  emits: ['closeBottomSheet', 'openBottomSheet', 'goNext', 'goPrev'],
  computed: {

    colorizeSVG() {
      if (this.isBottomSheetOpen || this.currentIndex === this.totalIndex) {
        return '#CCCCCC';
      } else {
        return '#333333';
      }
    },
    colorizeSVG2() {
      if (this.isBottomSheetOpen) {
        return '#CCCCCC';
      } else {
        return '#333333';
      }
    },
  },
  methods: {
    toggleBottomSheet() {
      logEvent('result_UI_index_click', {});
      if (this.isBottomSheetOpen) {
        this.$emit('closeBottomSheet');
      } else {
        this.bottomSheetType = 'index';
        this.$emit('openBottomSheet');
      }
    },
    goNext() {
      const isNotLastPage = this.currentIndex < this.totalIndex;
      const canProceed = !this.isBottomSheetOpen && isNotLastPage;

      if (canProceed) {
        logEvent('result_UI_next_click', {});
        this.$emit('goNext');
      }
    },
    goPrev() {
      if (!this.isBottomSheetOpen) {
        logEvent('result_UI_prev_click', {});
        this.$emit('goPrev');
      }
    },
  }
}
</script>

<style scoped>
.bottom-bar{
  width: 100dvw;
  max-width: 480px;
  padding: 8px 24px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;

  background: white;
  border-top: 1px solid var(--Grayscale-Gray10, #ECECEC);
}

.shades{
  position: absolute;
  top: -15px;
  left: 0;
  width: 100vw;
  height: 15px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.05) 100%);
  z-index: 100;
}

.pagination{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;

  color: var(--Grayscale-Gray3, #333);
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: 'dlig' on;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.navigation{
  display: flex;
  align-items: center;
  gap: 18px;
  font-size: 14px;
  transform: translateX(24px);
}

.shade{
  color: var(--Grayscale-Gray8, #CCC);
}

</style>